.cropper-content .cropper-box {
  width: 100%;
}
.cropper-content .cropper-box .cropper {
  width: 100%;
  height: 1000px;
}
.cropper-content .show-preview {
  margin-top: 20px;
}
.cropper-content .show-preview .preview {
  overflow: hidden;
  border: 1px solid #67c23a;
  background: #cccccc;
}
.footer-btn {
  margin-bottom: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
}
.footer-btn .scope-btn {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  padding-right: 10px;
}
.footer-btn .upload-btn {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.footer-btn .btn {
  outline: none;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  margin-right: 10px;
}
