 /deep/ .el-drawer__body {
  overflow: visible !important;
}
 /deep/ .el-drawer {
  overflow: visible !important;
}
.el-breadcrumb {
  margin-bottom: 15px;
}
.icon-exchangerate {
  font-size: 30px;
  float: right;
  text-align: center;
}
.icon-exchangerate:hover {
  font-size: 32px;
  color: #00FFFF;
}
.tag {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 6px;
}
